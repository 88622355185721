

















































































import { FlashErrorType } from '@vf/api-contract';
import {
  computed,
  defineComponent,
  ref,
  onMounted,
  watch,
} from '@vue/composition-api';
import {
  ROUTES,
  useCart,
  useCheckout,
  useI18n,
  useLoyalty,
  useNotification,
} from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import { useDiscountStore } from '@vf/composables/src/store/discountStore';
import { useOrderStore } from '@vf/composables/src/store/order';
import { PaymentMethodCode } from '@vf/api-client';

export default defineComponent({
  setup() {
    const { root } = useRootInstance();
    const { getLoyaltyVouchers, rewards } = useLoyalty(root);
    const { setFamilyReward, cart } = useCart(root);
    const { getPaymentMethods } = useCheckout(root);
    const { addNotification } = useNotification(root);
    const { localePath } = useI18n(root);

    const discountStore = useDiscountStore();
    const orderStore = useOrderStore();

    const isLoyaltyPaymentAvailable = computed(() =>
      orderStore.allAvailablePaymentMethods.some(
        ({ code }) => code === PaymentMethodCode.LOYALTY_POINTS
      )
    );

    const showRewardDetails = ref(false);
    const selectedRewardOption = ref<'selectAmount' | number>('selectAmount');

    const hasRewards = computed(() =>
      rewards.value?.applicableLoyaltyVouchers.some(
        (voucher) => voucher.available
      )
    );

    const rewardsToDisplay = computed(() => {
      return (
        rewards.value?.applicableLoyaltyVouchers.map((voucher, index) => ({
          id: voucher.amountInCurrency + `-${index}`,
          points: voucher.points,
          label: root.$t('familyRewards.rewardSelectOptionLabel', {
            price: root.$formatPrice(voucher.amountInCurrency, null, true),
            points: voucher.points,
          }) as string,
          disabled: !(voucher.available && voucher.eligible),
        })) || []
      );
    });

    const isMaximumRewardSelected = computed(() => {
      const selectedRewardAmount = !isNaN(Number(selectedRewardOption.value))
        ? Number(selectedRewardOption.value)
        : 0;

      return (
        selectedRewardAmount === minAndMaxAvailableRewards.value.max.points
      );
    });

    const doLoyaltyVouchersExist = computed(
      () => rewards.value?.applicableLoyaltyVouchers.length > 0
    );

    let isCartUpdatingWithVoucher = false;

    watch(
      cart,
      async (updatedCart: any) => {
        const hasPointsError = updatedCart.flash?.find(
          (error) => error.code === FlashErrorType.LoyaltyPointsNotApplicable
        );
        if (hasPointsError) {
          addNotification({
            message: root.$t('familyRewards.orderUpdated') as string,
            type: 'info',
          });
        }

        // if in cart page, refetch vouchers and payment methods on cart change
        // but only in case it's not an update coming from applying a voucher (:
        if (
          !isCartUpdatingWithVoucher &&
          root.$route.path === localePath(ROUTES.CART())
        ) {
          await getPaymentMethods();
          if (isLoyaltyPaymentAvailable.value) {
            getLoyaltyVouchers();
          }
        }
        isCartUpdatingWithVoucher = false;
      },
      { immediate: true }
    );

    watch(
      () => discountStore.appliedFamilyVoucher,
      (newVoucher) => {
        const newPointsApplied = Number(newVoucher?.loyaltyPoints);
        if (newPointsApplied === Number(selectedRewardOption.value)) {
          return;
        }

        selectedRewardOption.value =
          Number(newVoucher?.loyaltyPoints) || 'selectAmount';
      },
      { immediate: true }
    );

    const amountOfPointsToReachMinReward = computed(() => {
      if (!doLoyaltyVouchersExist.value) {
        return;
      }

      return (
        minAndMaxAvailableRewards.value.min.points -
        rewards.value.currentLoyaltyPoints
      );
    });

    const minAndMaxAvailableRewards = computed(() => {
      if (!doLoyaltyVouchersExist.value) {
        return {
          min: { points: 0, amountInCurrency: 0 },
          max: { points: 0, amountInCurrency: 0 },
        };
      }

      return rewards.value.applicableLoyaltyVouchers.reduce(
        (minMax, reward) => {
          if (reward.available && reward.eligible) {
            if (+reward.amountInCurrency < +minMax.min.amountInCurrency)
              minMax.min = {
                ...reward,
                amountInCurrency: +reward.amountInCurrency,
              };
            if (+reward.amountInCurrency > +minMax.max.amountInCurrency)
              minMax.max = {
                ...reward,
                amountInCurrency: +reward.amountInCurrency,
              };
          }
          return minMax;
        },
        {
          min: {
            points: +rewards.value.applicableLoyaltyVouchers[0].points,
            amountInCurrency: +rewards.value.applicableLoyaltyVouchers[0]
              .amountInCurrency,
          },
          max: { points: 0, amountInCurrency: 0 },
        }
      );
    });

    const availableRewardsLabel = computed(() => {
      const isOnlyFirstVoucherAvailable =
        !rewardsToDisplay.value[0]?.disabled &&
        rewardsToDisplay.value[1]?.disabled;

      const label = isOnlyFirstVoucherAvailable
        ? 'familyRewards.firstRewardAvailable'
        : 'familyRewards.availableRewards';

      return root.$t(label, {
        maxAvailableReward: root.$formatPrice(
          minAndMaxAvailableRewards.value.max.amountInCurrency,
          null,
          true
        ),
      });
    });

    const aboveSelectCopy = computed(() => {
      if (isNaN(Number(selectedRewardOption.value)))
        return availableRewardsLabel.value;
      else if (isMaximumRewardSelected.value)
        return root.$t('familyRewards.rewardAppliedNoRemainingRewards');
      else return root.$t('familyRewards.rewardAppliedStillHaveRewards');
    });

    const noRewardsLabel = computed(() =>
      root.$t('familyRewards.doNotHaveAnyAvailableRewards', {
        amountOfPoints: amountOfPointsToReachMinReward.value,
        minValueReward: root.$formatPrice(
          minAndMaxAvailableRewards.value.min.amountInCurrency,
          null,
          true
        ),
      })
    );

    async function applyReward(rewardAmount: string) {
      // if applying "save reward for later" when no reward is selected, do nothing
      if (!discountStore.appliedFamilyVoucher && rewardAmount == '0') {
        selectedRewardOption.value = 'selectAmount';
        return;
      }

      try {
        isCartUpdatingWithVoucher = true;
        await setFamilyReward(rewardAmount);
      } catch (err: unknown) {
        isCartUpdatingWithVoucher = false;
      }
    }

    onMounted(async () => {
      await getLoyaltyVouchers();
    });

    return {
      aboveSelectCopy,
      applyReward,
      hasRewards,
      isLoyaltyPaymentAvailable,
      noRewardsLabel,
      rewardsToDisplay,
      selectedRewardOption,
      showRewardDetails,
    };
  },
});
