// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../ui/assets/klarnaIcon.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".klarna[data-v-6f3e1e4c]{--klarna-info-margin:var(--spacer-sm) 0;--klarna-info-margin:var(--spacer-xs) 0 4px 0;line-height:var(--leading-3);margin:var(--klarna-info-margin)}.klarna__text[data-v-6f3e1e4c]{display:inline;font-size:var(--font-sm)}.klarna__text--redesign-core[data-v-6f3e1e4c]{font-size:var(--font-2sm)}.klarna__text--details[data-v-6f3e1e4c]{display:inline-block;font-size:var(--font-sm)}.klarna__text--details.klarna__text--details--redesign-core[data-v-6f3e1e4c]{font-family:var(--font-family-primary);font-size:var(--font-2sm);font-weight:var(--font-normal);text-underline-offset:auto}[data-v-6f3e1e4c] .klarnaIcon{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");display:inline-block;height:12px;width:54px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
