











import { defineComponent, computed } from '@vue/composition-api';
import { useAccount } from '@vf/composables';
import { replaceAll } from '@/helpers/replaceAll';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'LoyaltyRewardsAvailableToast',
  props: {
    totalRewards: String,
  },
  setup(props, { emit }) {
    const { root } = useRootInstance();
    const { basicInformation } = useAccount(root);

    const toastContent = computed(() => {
      const { firstName } = basicInformation.value || {};

      return replaceAll(
        root.$t('loyaltyRewardsAvailableToast.toastGreeting') as string,
        {
          firstName,
          currencyAmount: props.totalRewards,
        }
      );
    });

    const closeLoyaltyRewardsBanner = () => {
      sessionStorage.setItem('isLoyaltyRewardsAvailableBannerHidden', 'true');
      emit('close:loyalty-banner');
    };

    return {
      toastContent,
      closeLoyaltyRewardsBanner,
    };
  },
});
